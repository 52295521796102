/* eslint-disable security/detect-object-injection */
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { MoneyAmount } from '@andes/money-amount';

import StyledLabel from './styled-label';
import IconFull from '../icons/full';
import HandShake from '../icons/hand-shake';
import IconFactoryModule from '../icons/factory-builder';

const TYPE_PRICE = 'price';
const TYPE_ICON = 'icon';

const INTERPOLATION_REGEXP = /{([\s\S]+?)}/g;

const IconFactory = IconFactoryModule([
  IconFull,
  HandShake,
]);

/**
 * @param {string} text
 * @param {object} values
 */
const interpolator = (
  text,
  values,
  iconFactory = IconFactory,
  className = null,
) => {
  if (isEmpty(values)) return text;

  const result = text.split(INTERPOLATION_REGEXP);
  for (let index = 0; index < result.length; index += 1) {
    const key = result[index];
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      const replacement = values[key];
      switch (replacement.type) {
        case TYPE_PRICE: {
          const { symbol, currencyId, fraction, cents } = replacement;
          result[index] = (
            <MoneyAmount
              value={{
                fraction,
                cents,
              }}
              className={className}
              symbol={symbol}
              currencyId={currencyId}
              centsType="superscript"
              size={16}
              compact
            />
          );
          break;
        }
        case TYPE_ICON:
        case TYPE_ICON.toUpperCase():
          result[index] = iconFactory(replacement, className);
          break;
        default:
          result[index] = (
            <StyledLabel
              key={replacement.text}
              as="span"
              {...replacement}
              className
            />
          );
      }
    }
  }
  return result;
};

export default interpolator;
