/* eslint-disable max-len */
import React from 'react';
import classnames from 'classnames';

import Props from './props';

const namespace = 'ui-homes-icon ui-homes-icon--hand-shake';
const ICON_ID = 'hand_shake_icon';

const IconHandShake = ({ className = null }) => (
  <svg
    className={classnames(namespace, className)}
    width="19"
    height="13"
    viewBox="0 0 19 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconHandShake.propTypes = Props.propTypes;

const Icon = React.memo(IconHandShake);
Icon.ICON_ID = ICON_ID;

export default Icon;
