const internalIncludes = (el, search) => {
  try {
    return el.indexOf(search) !== -1;
  } catch (e) {
    return false;
  }
};

const stringIncludes = internalIncludes;

const internalFind = (el, id) => el.find(c => c.id === id);

export { internalFind as arrayFind, internalIncludes as arrayIncludes, stringIncludes };
