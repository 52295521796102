import sizenames from './sizenames';
import colornames from './colornames';
import familynames from './familynames';
import backgroundColor from './background-color';
import textAlignment from './text-alignment';
import margins from './margins';

const applyClasses = ({ alignment, size, family, color, bg_color, margin, className }) => textAlignment(
  alignment,
  backgroundColor(bg_color?.toUpperCase(), colornames(color, sizenames(size, familynames(family, margins(margin, className))))),
);

export default applyClasses;
