import classnames from 'classnames';

const DEFAULT_COLOR = false;

const namespace = 'font-color';

const colornames = (color, className) => {
  const colorName = typeof color === 'string' ? color.toUpperCase() : (color && color.color) || DEFAULT_COLOR;
  return classnames({ [`${namespace}--${colorName}`]: colorName }, className);
};

export default colornames;
