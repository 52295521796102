import React from 'react';
import { string, node, oneOf, shape, number, func } from 'prop-types';
import StyledLabel from './styled-label';
import interpolator from './interpolator';

const namespace = 'ui-styled-label-formated';

const DEFAULT_AS = 'p';

const StyledLabelFormated = ({
  as = DEFAULT_AS,
  className,
  classNameInterpolator,
  dataModel,
  iconFactory,
  children,
  ...forwardProps
}) => {
  const { text, accessibilityText, styles, values } = dataModel;

  return (
    <StyledLabel
      text={values ? interpolator(text, values, iconFactory, classNameInterpolator) : text}
      {...{
        as,
        ...styles,
        accessibilityText,
        ...forwardProps,
        className: className ? `${namespace} ${className}` : namespace,
      }}
    > {children}
    </StyledLabel>
  );
};

export default StyledLabelFormated;

StyledLabelFormated.propTypes = {
  as: oneOf(['p', 'span', 'h1', 'h2', 'h3', 'h4', 'li', 'a', 'del']),
  children: node,
  className: string,
  classNameInterpolator: string,
  dataModel: shape({
    alignment: oneOf(['center', 'left', 'right']),
    margin: shape({
      top: number,
      bottom: number,
      left: number,
      right: number,
    }),
    color: string,
    font_family: string,
    font_size: string,
    accessibilityText: string,
  }),
  iconFactory: func,
};

