import React from 'react';
import { object } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import displayName from '../display-name';
import componentCtrService from '../../../services/component-ctr';

function withTrackerOnPrint(WrappedComponent) {
  class WithTrackerOnPrint extends React.Component {
    componentDidMount() {
      const { reco_track = null } = this.props;
      if (!isEmpty(reco_track)) {
        componentCtrService.trackRecommendations(reco_track);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  WithTrackerOnPrint.displayName = displayName(WithTrackerOnPrint, WrappedComponent);

  WithTrackerOnPrint.propTypes = {
    reco_track: object,
  };

  return WithTrackerOnPrint;
}

export default withTrackerOnPrint;
