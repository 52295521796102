import React from 'react';
import { string, bool, node, oneOf, oneOfType, shape, number } from 'prop-types';
import applyclasses from '../../../lib/apply-classes';
import { arrayIncludes } from '../../../lib/array';
import entries from '../../../lib/object-entries';

const DEFAULT_AS = 'p';
const DEFAULT_FONT_FAMILY = 'REGULAR';

const createMarkup = text => ({ __html: text });

const allowedForwardedProps = ['href', 'target', 'id', 'onClick', 'data-testid'];

const filterForwardProps = (forwardProps = {}) => entries(forwardProps).reduce((acc, [name, value]) => {
  if (arrayIncludes(allowedForwardedProps, name)) {
    // eslint-disable-next-line security/detect-object-injection
    acc[name] = value;
  }
  return acc;
}, {});

// Map from BE props to FE naming
const StyledLabel = ({
  as = DEFAULT_AS,
  alignment = null,
  withDangerous = false,
  text = null,
  color = null,
  bg_color = null,
  font_family = DEFAULT_FONT_FAMILY,
  font_size = null,
  className = null,
  margin = null,
  accessibilityText = null,
  children = null,
  isAccessibilityHidden = false,
  ...forwardProps
}) => {
  const classes = applyclasses({
    size: font_size,
    family: font_family,
    alignment,
    color,
    bg_color,
    className,
    margin,
  });
  const Element = as;
  const filteredForwardedProps = filterForwardProps(forwardProps);
  const addAccessibilityHidden = {};

  if (isAccessibilityHidden) {
    addAccessibilityHidden['aria-hidden'] = true;
  }

  if (withDangerous) {
    return (
      <Element
        className={classes}
        {...addAccessibilityHidden}
        {...filteredForwardedProps}
        dangerouslySetInnerHTML={createMarkup(text)}
      />
    );
  }

  return (
    <Element className={classes} {...addAccessibilityHidden} {...filteredForwardedProps}>
      {text}
      {accessibilityText && <span className="andes-visually-hidden">{accessibilityText}</span>}
      {children}
    </Element>
  );
};

StyledLabel.propTypes = {
  as: oneOf(['p', 'span', 'h1', 'h2', 'h3', 'h4', 'li', 'a', 'del', 'div']),
  alignment: oneOf(['center', 'left', 'right']),
  withDangerous: bool,
  bg_color: string,
  className: string,
  text: oneOfType([string, node]),
  margin: shape({
    top: number,
    bottom: number,
    left: number,
    right: number,
  }),
  color: string,
  font_family: string,
  font_size: string,
  accessibilityText: string,
  children: node,
  isAccessibilityHidden: bool,
};

export default StyledLabel;
