const entries = obj => {
  const ownProps = Object.keys(obj);
  let i = ownProps.length;
  const resArray = new Array(i);

  // eslint-disable-next-line security/detect-object-injection, no-plusplus
  while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

  return resArray;
};

export default entries;
